import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import records from './modules/records'
import orders from './modules/orders'
import { $axios } from '@/utils/axios';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    records,
    orders
  },  
  state: {
    loading: false,
    disabledSidebar: false,
    settings: null,
    dictionary: null,
  },
  getters: {
    isLoading: state => state.loading,
    disabledSidebar: state => state.disabledSidebar,
    settings: state => state.settings,
    dictionary: state => state.dictionary,
    storage: state => state.settings?.filter(setting => setting.name === 'url_admin')[0],
    logo: state => state.settings?.filter(setting => setting.name === 'logo')[0],
    calendar: state => state.settings?.filter(setting => setting.name === 'calendar_code_technical')[0],
    clientUrl: state => state.settings?.filter(setting => setting.name === 'url_client')[0],
  },  
  mutations: {
    setLoading (state, isLoading) {
      state.loading = isLoading
    },      
    setDisabledSidebar (state, disabledSidebar) {
      state.disabledSidebar = disabledSidebar
    },
    setSettings (state, payload) {
      state.settings = payload
    },
    setDictionary (state, payload) {
      state.dictionary = payload
    }
  },
  actions: {
    setLoading({commit}, isLoading) {
      return new Promise((resolve) => {
        commit('setLoading', isLoading)
        resolve()
      })
    },     
    setDisabledSidebar({commit}, disabledSidebar) {
      return new Promise((resolve) => {
        commit('setDisabledSidebar', disabledSidebar)
        resolve()
      })
    },
    getAppSettings: ({commit, dispatch}) => {
      dispatch('setLoading', true, {root: true})

      return new Promise((resolve, reject) => {
        $axios.get(`/setting?page=1&take=30&sort_field=name&sort_order=asc`)
        .then(resp => {
          resolve(resp.data)
          commit('setSettings', resp.data.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => dispatch('setLoading', false, {root: true}))
      })        
    },
    getAppDictionary: ({commit, dispatch}) => {
      dispatch('setLoading', true, {root: true})

      return new Promise((resolve, reject) => {
        $axios.get(`/dictionary`)
        .then(resp => {
          resolve(resp.data)
          commit('setDictionary', resp.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => dispatch('setLoading', false, {root: true}))
      })        
    },               
  }
})
