// import Rollbar from 'rollbar';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import Buefy from 'buefy'
import {VueFeatherIconsSsr as icon} from 'vue-feather-icons-ssr'
import './vee-validate'
import './assets/scss/_main.scss'
import Maska from 'maska'
import Hotjar from 'vue-hotjar'
import { vfmPlugin } from 'vue-final-modal'
// Vue.prototype.$rollbar = new Rollbar({
//   accessToken: 'b0d56dd736d4479ab2f79187230829f5',
//   captureUncaught: true,
//   captureUnhandledRejections: true,
// });

Vue.config.productionTip = false
Vue.use(Buefy)
Vue.use(PortalVue)
Vue.component('icon', icon)
Vue.use(Maska)
Vue.use(vfmPlugin)

Vue.use (Hotjar, {
  id: '3378195',
  isProduction: true,
})

// Vue.config.errorHandler = (err, vm) => {
//   vm.$rollbar.error(err);
//   throw err; 
// };

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
