import { $axios } from "@/utils/axios";

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    getCeidg: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/ceidg/${payload.nip}?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getCustomers: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `page=${payload.page}`,
        `take=${payload.perPage}`,
        `sort_field=${payload.sortField}`,
        `sort_order=${payload.sortOrder}`,
        `search=${payload.searchQuery}`,
        `search_deleted=${payload.searchDeleted}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/customer?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getCustomer: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/customer/${payload.id}?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postCustomer: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      const { address, city, email, name, nip, phone, postcode, shortname } =
        payload.customer;
      const id = payload.id;
      const method = id ? "put" : "post";
      const url = id ? `/customer/${id}` : "/customer";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: {
            address,
            city,
            email,
            name,
            nip,
            phone,
            postcode,
            shortname,
            access_token: rootState.auth.token,
          },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    removeCustomer: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(
            `/customer/${payload.id}?access_token=${rootState.auth.token}`
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getUsers: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `function_role=${payload.function_role}`,
        `page=${payload.page}`,
        `take=${payload.perPage}`,
        `sort_field=${payload.sortField}`,
        `sort_order=${payload.sortOrder}`,
        `search=${payload.searchQuery}`,
        `search_deleted=${payload.searchDeleted}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/user?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getUser: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/user/${payload.id}?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postUser: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      const {
        firmname,
        firstname,
        lastname,
        email,
        password,
        phone,
        nip,
        postcode,
        city,
        address,
        function_role,
        comment,
      } = payload.user;
      const id = payload.id;
      const method = id ? "put" : "post";
      const url = id ? `/user/${id}` : "/user";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: {
            firmname,
            firstname,
            lastname,
            email,
            password,
            phone,
            nip,
            postcode,
            city,
            address,
            function_role,
            comment,
            access_token: rootState.auth.token,
          },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    removeUser: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(`/user/${payload.id}?access_token=${rootState.auth.token}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getMachineries: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `page=${payload.page}`,
        `take=${payload.perPage}`,
        `sort_field=${payload.sortField}`,
        `sort_order=${payload.sortOrder}`,
        `search=${payload.searchQuery}`,
        `search_deleted=${payload.searchDeleted}`,
        // `customer_id=${payload.customer_id}`
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/device?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getMachinery: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/device/${payload.id}?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postMachinery: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      const { serial, type, name } = payload.machinery;
      const id = payload.id;
      const method = id ? "put" : "post";
      const url = id ? `/device/${id}` : "/device";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: { serial, type, name, access_token: rootState.auth.token },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    removeMachinery: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(`/device/${payload.id}?access_token=${rootState.auth.token}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getServices: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `page=${payload.page}`,
        `take=${payload.perPage}`,
        `sort_field=${payload.sortField}`,
        `sort_order=${payload.sortOrder}`,
        `search=${payload.searchQuery}`,
        `search_deleted=${payload.searchDeleted}`,
        // `customer_id=${payload.customer_id}`
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/service?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getService: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/service/${payload.id}?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postService: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      const { category, name, price, minutes, buy_price, note } =
        payload.service;
      const id = payload.id;
      const method = id ? "put" : "post";
      const url = id ? `/service/${id}` : "/service";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: {
            category,
            name,
            price,
            minutes,
            buy_price,
            note,
            access_token: rootState.auth.token,
          },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    removeServices: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(`/service/${payload.id}?access_token=${rootState.auth.token}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    getUserNote: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [`access_token=${rootState.auth.token}`].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/note/${payload.id}?${params}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    postUserNote: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      const { note, user_ids } = payload.note;
      const id = payload.id;
      const method = id ? "put" : "post";
      const url = id ? `/note/${id}` : "/note";

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: {
            note,
            user_ids,
            access_token: rootState.auth.token,
          },
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    removeUserNote: ({ dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });
      $axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.token}`;

      return new Promise((resolve, reject) => {
        $axios
          .delete(`/note/${payload.id}?access_token=${rootState.auth.token}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
  },
};
